import React, { ReactElement } from "react";

import dynamic from "next/dynamic";

import { FooterData } from "data/footer";
import { Footer } from "studio-design-system";

const MarketingFooterContent = dynamic(() => import("./MarketingFooterContent"));

interface Props {
  data: FooterData;
}
export const MarketingFooter = (props: Props): ReactElement => (
  <Footer>
    <MarketingFooterContent data={props.data} />
  </Footer>
);
