import React, { ReactElement } from "react";

import classNames from "classnames";
import Link from "next/link";

import { DotSeparatorIcon } from "~/components/common/Icons/DotSeparatorIcon";
import { convertSecondsToFriendlyDuration } from "~/util/stringUtils";

import { ProfilePic } from "../ProfilePic";
import { CreatorCheck } from "./CreatorCheck";
import * as styles from "./CreatorInfo.css";
import { CreatorInfoInterface } from "./CreatorInfoInterface";

export const CreatorInfo = ({
  slug,
  avatar,
  name,
  style,
  isPurchasedVideo = false,
  hideVideoLength = false,
  disableLink = false,
  totalVideoLengthInSeconds,
  singleColumnGrid = false,
  creatorType,
  isMusicHomepage = false,
  showCheckIcon = true,
}: CreatorInfoInterface): ReactElement => {
  const CreatorInfoBox = () => (
    <>
      <ProfilePic
        size={isPurchasedVideo ? 24 : 32}
        imageUrl={avatar}
        firstName={name}
        cropFromTop={true}
      ></ProfilePic>
      <span
        className={classNames(
          creatorType ? styles.creatorNameWithoutBorder : styles.creatorName,
          singleColumnGrid && styles.creatorNameSingleColumnGrid,
          isPurchasedVideo && styles.creatorNameIsPurchased,
        )}
      >
        {name}
      </span>
      {creatorType && <span className={styles.creatorType}>{creatorType}</span>}
      {isMusicHomepage && !creatorType ? (
        <span className={styles.instructorLabel}>Instructor</span>
      ) : showCheckIcon ? (
        <CreatorCheck />
      ) : (
        <></>
      )}
    </>
  );

  return (
    <div
      className={classNames(
        !disableLink ? styles.creatorInfoWrap : styles.creatorInfoWrapWithoutHover,
        isPurchasedVideo ? styles.libraryCreatorInfo : "",
      )}
      style={style}
    >
      {!disableLink ? (
        <Link passHref href={`/classes/${slug}`}>
          <a
            className={classNames(
              styles.creatorLink,
              isPurchasedVideo && styles.creatorLinkIsPurchased,
            )}
          >
            {CreatorInfoBox()}
          </a>
        </Link>
      ) : (
        <div
          className={classNames(
            styles.creatorLink,
            isPurchasedVideo && styles.creatorLinkIsPurchased,
          )}
        >
          {CreatorInfoBox()}
        </div>
      )}

      {isPurchasedVideo && !hideVideoLength && (
        <>
          <DotSeparatorIcon />
          <span className={styles.videoLength}>
            {convertSecondsToFriendlyDuration(
              totalVideoLengthInSeconds ? totalVideoLengthInSeconds : 0,
            )}
          </span>
        </>
      )}
    </div>
  );
};
