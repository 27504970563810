import "../../../../node_modules/@vanilla-extract/webpack-plugin/vanilla.virtual.css?%7B%22fileName%22%3A%22src%2Fcomponents%2Fcommon%2FProfilePic%2FGradientProfilePic.css.ts.vanilla.css%22%2C%22source%22%3A%22%23H4sIAAAAAAAAA5WR246bMBBA3%2FMVI0WREgkjG2xz2ScC5DcqA4Z4S0xkzCa7Vf%2B9ArrdIiI1fTEez%2FGZYex%2BI28fr7i%2BYfixAahUf23Fewx1K%2B8vG5i%2BqFJGllZ1Ooaya4eLHjOvQ29V%2FY7KTlupbQyl1FaaMSVa1WikrLz0fx%2BXXduZGLZ1XddjXHSmkgYZUamhj4FgvBuPb6qy56%2FwLFVztl%2FxfCuGN2H2CA1c2PbMysOYsvJukTVC93VnLjEM16s0pejlfO%2BOevWhdBN%2FVi666R%2BHXhrUy1aWNgbd6QmvO23R7XdtivHL5ufG%2FRwWmYZViPJ7Y7pBVzFsMx5FHl1Q3poqWRBSsqD8NUVZVIXLinRNYRzUIlhQbE1xjxelWFB8TRWCEeovqGBFtUpLYVAzPpjUdu%2BTsJKNA9s09UKeAiKRG4U7B0xTiL3HPAcICxwg1AHs%2BvgAHqYu93cHB0aHaL9chIRu4O2AUOJSbwfCTg8%2BLQ5sT1l2zDDM%2B9MpOR6nzGHRcfjPjonP5o5P7JgxPvuyI88ZfeCLnvfl9OQnFLCLyajEOOGR90Apnlf6fhZmdG6RpyzNgwe%2B4nlfxhOWh7OPEUrS9IGv%2FI8R5gmPktmXB0GQhn98vwCIy1eBVQQAAA%3D%3D%22%7D"
export var blue = '_1vzj0fw4';
export var blueGradient = '_1vzj0fwa';
export var green = '_1vzj0fw3';
export var greenGradient = '_1vzj0fw9';
export var orange = '_1vzj0fw1';
export var orangeGradient = '_1vzj0fw8';
export var pic = '_1vzj0fw0';
export var pink = '_1vzj0fw6';
export var pinkGradient = '_1vzj0fwc';
export var purple = '_1vzj0fw5';
export var purpleGradient = '_1vzj0fwb';
export var redGradient = '_1vzj0fw7';
export var yellow = '_1vzj0fw2';