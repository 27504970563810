import React, { useState } from "react";

import classNames from "classnames";

import * as styles from "./GradientProfilePic.css";

enum COLORS {
  ORANGE = "orange",
  YELLOW = "yellow",
  GREEN = "green",
  BLUE = "blue",
  PURPLE = "purple",
  PINK = "pink",
}

enum GRADIENTS {
  RED = "redGradient",
  ORANGE = "orangeGradient",
  GREEN = "greenGradient",
  BLUE = "blueGradient",
  PURPLE = "purpleGradient",
  PINK = "pinkGradient",
}

interface EmptyProfilePicProps {
  firstName?: string;
  userId?: string;
  gradient?: boolean;
}

const hashString = (s: string) => {
  let hash = 0;
  if (!s || s.length === 0) return hash;
  for (let i = 0; i < s.length; i++) {
    const chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
};

export const getColorStyle = (uniqueId: string): string => {
  const colorList = Object.values(COLORS);
  const hash = hashString(uniqueId);
  const thumbnailColor = colorList[uniqueId ? hash % colorList.length : 0];
  const colorStyles: { [color: string]: string } = {
    [COLORS.ORANGE]: styles.orange,
    [COLORS.YELLOW]: styles.yellow,
    [COLORS.GREEN]: styles.green,
    [COLORS.BLUE]: styles.blue,
    [COLORS.PURPLE]: styles.purple,
    [COLORS.PINK]: styles.pink,
  };

  return colorStyles[thumbnailColor];
};

export const getGradientStyle = (uniqueId: string): string => {
  const gradientList = Object.values(GRADIENTS);
  const hash = hashString(uniqueId);
  const thumbnailGradient = gradientList[uniqueId ? hash % gradientList.length : 0];
  const gradientStyles: { [gradient: string]: string } = {
    [GRADIENTS.RED]: styles.redGradient,
    [GRADIENTS.ORANGE]: styles.orangeGradient,
    [GRADIENTS.GREEN]: styles.greenGradient,
    [GRADIENTS.BLUE]: styles.blueGradient,
    [GRADIENTS.PURPLE]: styles.purpleGradient,
    [GRADIENTS.PINK]: styles.pinkGradient,
  };

  return gradientStyles[thumbnailGradient];
};

export const EmptyProfilePic = ({
  firstName,
  userId,
  gradient = false,
}: EmptyProfilePicProps): React.ReactElement => {
  const [fontSize, setFontSize] = useState(16);
  const initials = firstName ? String.fromCodePoint(firstName.codePointAt(0) ?? 0) : "";

  const fontStyle = { fontSize };

  const colorStyle =
    firstName && userId
      ? gradient
        ? getGradientStyle(userId)
        : getColorStyle(firstName)
      : getGradientStyle("");

  //use height bc should be same as width for propic but unaffected by sidebar open/close
  const updateFont = (div: HTMLDivElement) => {
    const newFontSize = div?.offsetHeight / 2;
    newFontSize > 0 && setFontSize(newFontSize);
  };

  return (
    <div ref={updateFont} className={classNames(styles.pic, colorStyle)} style={fontStyle}>
      {initials}
    </div>
  );
};
