import React, { useState, useMemo } from "react";

import classNames from "classnames";
import Image from "next/image";

import { AnimatedSpinner } from "studio-design-system";

import { ImageWithBlur } from "~/components/common/ImageWithBlur/ImageWithBlur";
import { addCloudinaryParameters, getResponsiveImageURL } from "~/util/cloudinary-utils";

import { EmptyProfilePic } from "./GradientProfilePic";
import * as styles from "./ProfilePic.css";

export const DEFAULT_PROFILE_PIC_SIZE = 30;

interface Props {
  size?: number;
  borderRadius?: string;
  firstName?: string;
  imageUrl?: string;
  userId?: string;
  isLoading?: boolean;
  className?: string;
  cropFromTop?: boolean;
  gradient?: boolean;
}

export const ProfilePic = ({
  className,
  size = DEFAULT_PROFILE_PIC_SIZE,
  borderRadius = "50%",
  isLoading,
  firstName,
  userId,
  imageUrl,
  cropFromTop = false,
  gradient = false,
}: Props): React.ReactElement => {
  const style = {
    width: size,
    height: size,
    borderRadius: borderRadius,
  };

  const [imageFailedToLoad, setImageFailedToLoad] = useState(false);

  const altText = `Profile picture for ${firstName}`;

  const getProfileSizedImage = () => {
    if (!imageUrl) {
      //console.error(`Failed to get profile sized image: missing imageUrl`);
      return "";
    }
    // TODO: incorporate window.devicePixelRatio if possible.
    // for now, setting to 2 is a good easy middleground solution
    const dpr = 2;
    return addCloudinaryParameters(imageUrl, [
      "a_exif",
      "c_limit",
      "q_auto:best",
      "f_auto",
      `h_${Math.ceil(size * 3)}`,
      `dpr_${dpr}`,
    ]);
  };

  const emptyProfilePic = (
    <div className={className} style={style} role="img" aria-label={altText}>
      <EmptyProfilePic firstName={firstName} userId={userId} gradient={gradient} />
    </div>
  );

  // const profileImageUrl = getProfileSizedImage();
  const profileImageUrl = getProfileSizedImage() || "";

  const getContent = () => {
    if (isLoading) {
      return <AnimatedSpinner />;
    }
    if (imageFailedToLoad || !imageUrl || imageUrl === "") {
      // console.error(`Failed to load profile image for ${firstName} with imageUrl: ${imageUrl}`);
      return emptyProfilePic;
    }
    // <Image
    //     alt={altText}
    //     src={profileImageUrl}
    //     onError={(e) => {
    //       e.currentTarget.onerror = null;
    //       setImageFailedToLoad(true);
    //     }}
    //     aria-label={altText}
    //     style={{
    //       borderRadius: "50%",
    //       objectFit: "cover",
    //     }}
    //     objectFit="cover"
    //     objectPosition={cropFromTop ? "top" : "center"}
    //     height={size}
    //     width={size}
    //     unoptimized={true}
    //   />
    return (
      <ImageWithBlur
        alt={altText}
        src={getResponsiveImageURL(imageUrl, `${size * 3}`) || "100"}
        width={size}
        height={size}
        layout={"fixed"}
        objectFit={"cover"}
        objectPosition={cropFromTop ? "top" : "center"}
        style={{ borderRadius: borderRadius, objectFit: "cover" }}
      />
    );
  };

  return <div className={classNames(styles.container, className)}>{getContent()}</div>;
};
