import React, { useState, useEffect } from "react";

import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import posthog from "posthog-js";

import { YellowBannerObject } from "data/homepage";
import { ProductData, UserProductPurchaseData } from "data/product/products";
import { Button } from "studio-design-system";

import { CollectionInfo } from "~/components/common/CollectionInfo";
import { CreatorInfo } from "~/components/common/CreatorInfo";
import { ImageWithBlur } from "~/components/common/ImageWithBlur/ImageWithBlur";
import { VideoProgressBar } from "~/components/videoProductPage/common/VideoProgressBar/VideoProgressBar";
import { useUserPurchases } from "~/hooks/purchase/useUserPurchases";
import { useIsMobileScreen } from "~/hooks/ui/useIsMobileScreen";
import { getResponsiveImageURL } from "~/util/cloudinary-utils";
import {
  formatMoney,
  getPathForProduct,
  getPathForSchool,
  getProductWatchPath,
} from "~/util/stringUtils";

import * as styles from "./ClassCard.css";

export const ClassCard = ({
  productData,
  purchaseData,
  showAddToCart,
  onAddToCart,
  onClassClick,
  showPriceOffer,
  alreadyInCart,
  isHomePage,
  isMusicHomepage,
  showCheckIcon = true,
  singleColumnGrid,
  isFourColumnsGrid,
  shouldOpenInNewTab,
  hideButtonRow,
  hideDesc,
  creatorType,
  showProgrees,
}: {
  productData: ProductData;
  purchaseData?: UserProductPurchaseData;
  showAddToCart?: boolean;
  onAddToCart?: () => void;
  onClassClick?: () => void;
  showPriceOffer?: boolean;
  alreadyInCart?: boolean;
  isHomePage?: boolean;
  isMusicHomepage?: boolean;
  showCheckIcon?: boolean;
  singleColumnGrid?: boolean;
  isFourColumnsGrid?: boolean;
  shouldOpenInNewTab?: boolean;
  hideButtonRow?: boolean;
  hideDesc?: boolean;
  creatorType?: string;
  showProgrees?: boolean;
}): React.ReactElement => {
  const isMobileScreen = useIsMobileScreen();
  const router = useRouter();
  const { collectionSlug } = router.query;
  const { hasUserPurchasedProduct } = useUserPurchases();
  const [offerTheme, setOfferTheme] = useState("none");

  const getClickPathForItem = () => {
    if (hasUserPurchasedProduct(productData.id)) {
      return getProductWatchPath(productData);
    }

    if (productData.isSchool) {
      return getPathForSchool(productData);
    }

    return getPathForProduct(productData);
  };

  useEffect(() => {
    // posthog.feature_flags.override({ "flip-purchase-button": "buy-now-priority" });
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled("homepage-yellow-banner-text")) {
        const yellowBannerInfo = posthog.getFeatureFlagPayload(
          "homepage-yellow-banner-text",
        ) as unknown as YellowBannerObject;
        if (yellowBannerInfo) {
          setOfferTheme(yellowBannerInfo.specialTheme);
        }
      }
    });
  }, []);

  const buildProgress = (purchaseData: UserProductPurchaseData) => {
    let totalLessons = 1;
    let lastLessonIndex = 1;
    let percentage = purchaseData.lastPositionPercentage ? purchaseData.lastPositionPercentage : 0;
    if (purchaseData.contentProgress) {
      let contentProgress = Object.keys(JSON.parse(JSON.stringify(purchaseData.contentProgress)));
      totalLessons = contentProgress.length;
      if (purchaseData.latestEngagedContentId) {
        lastLessonIndex = contentProgress.indexOf(purchaseData.latestEngagedContentId);
        percentage =
          purchaseData.contentProgress[purchaseData.latestEngagedContentId].completionPercentage;
      }
    }

    return (
      <>
        <div className={styles.lessonBox}>
          Lesson {lastLessonIndex} of {totalLessons}
        </div>
        <VideoProgressBar progressColor={"white"} barHeight={7} progressPercentage={percentage} />
      </>
    );
  };

  const handleCardClick = () => {
    if (onClassClick) {
      onClassClick();
    }
  };

  const cardContent = (isCardPreviewNotClickable: boolean) => {
    return (
      <>
        <div onClick={handleCardClick} className={styles.imgWrapper}>
          {!isCardPreviewNotClickable && (
            <div className={styles.linearGradient}>
              <Button variant="secondary" buttonSize="medium" className={styles.viewClassButton}>
                {productData.isSchool
                  ? "Learn More"
                  : productData?.isCollection && hasUserPurchasedProduct(productData.id)
                    ? "Watch collection"
                    : productData?.isCollection
                      ? "View collection"
                      : !productData?.isCollection && hasUserPurchasedProduct(productData.id)
                        ? "Watch class"
                        : "View class"}
              </Button>
            </div>
          )}
          <ImageWithBlur
            src={getResponsiveImageURL(productData.productImageURL, "500") || ""}
            width={306}
            height={195}
            layout={"responsive"}
            objectFit={"cover"}
            alt={productData.title}
          />
          {purchaseData && <>{buildProgress(purchaseData)}</>}
        </div>
        <h2 className={singleColumnGrid ? styles.singleColumnClassTitle : styles.cardTitle}>
          {productData.title}
        </h2>
        {productData?.isCollection ? (
          <CollectionInfo
            totalClasses={productData.productsIncluded.length}
            totalHours={productData.totalHours}
            totalLessons={productData.totalLessons}
          />
        ) : productData.dateDescription ? (
          <div className={styles.cardDateDescription}>{productData.dateDescription}</div>
        ) : (
          <CreatorInfo
            avatar={productData.creatorProfileImageURL}
            name={productData.creatorName}
            slug={productData.storefrontSlug}
            creatorType={creatorType}
            style={{ marginBottom: 12 }}
            isPurchasedVideo={false}
            singleColumnGrid={singleColumnGrid}
            totalVideoLengthInSeconds={productData.totalVideoLengthInSeconds}
            disableLink={true}
            isMusicHomepage={isMusicHomepage}
            showCheckIcon={showCheckIcon}
          />
        )}
        {!hideDesc && (
          <span
            className={classNames(
              singleColumnGrid && styles.singleColumnDescription,
              styles.cardDescription,
            )}
          >
            {productData.cardPreviewDescriptionOverride ||
              productData.shortBio ||
              productData.description ||
              ""}
            {/* <ReactMarkdown>
              {productData.cardPreviewDescriptionOverride ||
                productData.shortBio ||
                productData.description ||
                ""}
            </ReactMarkdown> */}
          </span>
        )}
      </>
    );
  };
  return (
    <div
      className={classNames(
        showAddToCart && !isFourColumnsGrid ? styles.classCard3Col : styles.classCard,
        isHomePage ? styles.classCard2ColMobile : null,
      )}
    >
      {productData.isCardPreviewNotClickable ? (
        cardContent(productData.isCardPreviewNotClickable)
      ) : (
        <Link href={getClickPathForItem()} passHref target={shouldOpenInNewTab ? "_blank" : ""}>
          <a target={shouldOpenInNewTab ? "_blank" : ""}>{cardContent(false)}</a>
        </Link>
      )}

      {(!hideButtonRow || !isMusicHomepage) && (
        <>
          {!collectionSlug ? (
            <div className={classNames(styles.priceContainer, styles.justifySpaceBetween)}>
              {!hasUserPurchasedProduct(productData.id) ? (
                <>
                  {!productData.dateDescription && (
                    <Link href={getClickPathForItem()} passHref>
                      <a>
                        <h2
                          className={classNames(
                            styles.salePrice,
                            offerTheme !== "none" && styles.springTheme,
                            offerTheme === "holiday" && styles.holidayTheme,
                          )}
                        >
                          {formatMoney(
                            !showPriceOffer
                              ? productData.priceInfo?.crossedPrice
                              : productData.priceInfo?.price,
                          )}{" "}
                          {showPriceOffer && (
                            <small
                              className={classNames(
                                styles.regularPrice,
                                offerTheme !== "none" && styles.springTheme,
                                offerTheme === "holiday" && styles.holidayTheme,
                              )}
                            >
                              <del>{formatMoney(productData.priceInfo?.crossedPrice)}</del>
                            </small>
                          )}
                        </h2>
                      </a>
                    </Link>
                  )}
                  {!productData.isSchool && showAddToCart && !(isMobileScreen && isHomePage) && (
                    <Button
                      variant="secondary"
                      buttonSize="small"
                      onClick={() => {
                        onAddToCart && onAddToCart();
                      }}
                      className={styles.ctaButtonSmall}
                    >
                      {alreadyInCart ? "Go to cart" : "Add to cart"}
                    </Button>
                  )}
                  {productData.isSchool && (
                    <Link href={getPathForSchool(productData)} passHref>
                      <Button
                        variant="secondary"
                        buttonSize="small"
                        onClick={() => { }}
                        className={styles.ctaButtonExpandedHorizontally}
                      >
                        {"Learn More"}
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link passHref href={getProductWatchPath(productData)}>
                    <a>
                      <h2 className={styles.salePrice}>In Library</h2>
                    </a>
                  </Link>
                  {!(isMobileScreen && isHomePage) && (
                    <Button
                      variant="secondary"
                      buttonSize="small"
                      className={styles.ctaButtonSmall}
                    >
                      Watch now
                    </Button>
                  )}
                </>
              )}
            </div>
          ) : productData.isCardPreviewNotClickable ? (
            <div className={styles.priceContainer}>
              <h2 className={styles.salePrice}>
                {productData.isCardPreviewNotClickable ? "Collection Exclusive" : "Included"}
                <small className={styles.regularPrice}>
                  <del>{formatMoney(productData.priceInfo.crossedPrice)}</del>
                </small>
              </h2>
            </div>
          ) : (
            <Link href={getClickPathForItem()} passHref>
              <a>
                <div className={styles.priceContainer}>
                  <h2 className={styles.salePrice}>
                    {productData.isCardPreviewNotClickable ? "Collection Exclusive" : "Included"}
                    <small className={styles.regularPrice}>
                      <del>{formatMoney(productData.priceInfo.crossedPrice)}</del>
                    </small>
                  </h2>
                </div>
              </a>
            </Link>
          )}
        </>
      )}
    </div>
  );
};

export const LibraryClassCard = ({
  productData,
  purchaseData,
  showAddToCart,
  onAddToCart,
  onClassClick,
  showPriceOffer,
  alreadyInCart,
  isHomePage,
  isMusicHomepage,
  showCheckIcon = true,
  singleColumnGrid,
  isFourColumnsGrid,
  shouldOpenInNewTab,
  hideButtonRow,
  hideDesc,
  creatorType,
  showProgrees,
}: {
  productData: ProductData;
  purchaseData?: UserProductPurchaseData;
  showAddToCart?: boolean;
  onAddToCart?: () => void;
  onClassClick?: () => void;
  showPriceOffer?: boolean;
  alreadyInCart?: boolean;
  isHomePage?: boolean;
  isMusicHomepage?: boolean;
  showCheckIcon?: boolean;
  singleColumnGrid?: boolean;
  isFourColumnsGrid?: boolean;
  shouldOpenInNewTab?: boolean;
  hideButtonRow?: boolean;
  hideDesc?: boolean;
  creatorType?: string;
  showProgrees?: boolean;
}): React.ReactElement => {
  const isMobileScreen = useIsMobileScreen();
  const router = useRouter();
  const { collectionSlug } = router.query;
  const { hasUserPurchasedProduct } = useUserPurchases();
  const [offerTheme, setOfferTheme] = useState("none");

  const getClickPathForItem = () => {
    if (hasUserPurchasedProduct(productData.id)) {
      return getProductWatchPath(productData);
    }

    if (productData.isSchool) {
      return getPathForSchool(productData);
    }

    return getPathForProduct(productData);
  };

  useEffect(() => {
    // posthog.feature_flags.override({ "flip-purchase-button": "buy-now-priority" });
    posthog.onFeatureFlags(function () {
      if (posthog.isFeatureEnabled("homepage-yellow-banner-text")) {
        const yellowBannerInfo = posthog.getFeatureFlagPayload(
          "homepage-yellow-banner-text",
        ) as unknown as YellowBannerObject;
        if (yellowBannerInfo) {
          setOfferTheme(yellowBannerInfo.specialTheme);
        }
      }
    });
  }, []);

  const buildProgress = (purchaseData: UserProductPurchaseData) => {
    let totalLessons = 1;
    let lastLessonIndex = 1;
    let percentage = purchaseData.lastPositionPercentage ? purchaseData.lastPositionPercentage : 0;
    if (purchaseData.contentProgress) {
      let contentProgress = Object.keys(JSON.parse(JSON.stringify(purchaseData.contentProgress)));
      totalLessons = contentProgress.length;
      if (purchaseData.latestEngagedContentId) {
        lastLessonIndex = contentProgress.indexOf(purchaseData.latestEngagedContentId);
        percentage =
          purchaseData.contentProgress[purchaseData.latestEngagedContentId].completionPercentage;
      }
    }

    return (
      <>
        <div className={styles.lessonBox}>
          Lesson {lastLessonIndex} of {totalLessons}
        </div>
        <VideoProgressBar progressColor={"white"} barHeight={7} progressPercentage={percentage} />
      </>
    );
  };

  const handleCardClick = () => {
    if (onClassClick) {
      onClassClick();
    }
  };

  const cardContent = (isCardPreviewNotClickable: boolean) => {
    return (
      <>
        <div onClick={handleCardClick} className={styles.imgWrapper}>
          {!isCardPreviewNotClickable && (
            <div className={styles.linearGradient}>
              <Button variant="secondary" buttonSize="medium" className={styles.viewClassButton}>
                {productData.isSchool
                  ? "Learn More"
                  : productData?.isCollection && hasUserPurchasedProduct(productData.id)
                    ? "Watch collection"
                    : productData?.isCollection
                      ? "View collection"
                      : !productData?.isCollection && hasUserPurchasedProduct(productData.id)
                        ? "Watch class"
                        : "View class"}
              </Button>
            </div>
          )}
          <ImageWithBlur
            src={getResponsiveImageURL(productData.productImageURL, "500") || ""}
            width={306}
            height={195}
            layout={"responsive"}
            objectFit={"cover"}
            alt={productData.title}
          />
          {purchaseData && <>{buildProgress(purchaseData)}</>}
        </div>
        <h2 className={singleColumnGrid ? styles.singleColumnClassTitle : styles.cardTitle}>
          {productData.title}
        </h2>
        {productData?.isCollection ? (
          <CollectionInfo
            totalClasses={productData.productsIncluded.length}
            totalHours={productData.totalHours}
            totalLessons={productData.totalLessons}
          />
        ) : productData.dateDescription ? (
          <div className={styles.cardDateDescription}>{productData.dateDescription}</div>
        ) : (
          <CreatorInfo
            avatar={productData.creatorProfileImageURL}
            name={productData.creatorName}
            slug={productData.storefrontSlug}
            creatorType={creatorType}
            style={{ marginBottom: 12 }}
            isPurchasedVideo={false}
            singleColumnGrid={singleColumnGrid}
            totalVideoLengthInSeconds={productData.totalVideoLengthInSeconds}
            disableLink={true}
            isMusicHomepage={isMusicHomepage}
            showCheckIcon={showCheckIcon}
          />
        )}
        {!hideDesc && (
          <span
            className={classNames(
              singleColumnGrid && styles.singleColumnDescription,
              styles.cardDescription,
            )}
          >
            {productData.cardPreviewDescriptionOverride ||
              productData.shortBio ||
              productData.description ||
              ""}
            {/* <ReactMarkdown>
              {productData.cardPreviewDescriptionOverride ||
                productData.shortBio ||
                productData.description ||
                ""}
            </ReactMarkdown> */}
          </span>
        )}
      </>
    );
  };
  return (
    <div
      className={classNames(
        showAddToCart && !isFourColumnsGrid ? styles.classCard3Col : styles.classCard,
        isHomePage ? styles.classCard2ColMobile : null,
      )}
    >
      {productData.isCardPreviewNotClickable ? (
        cardContent(productData.isCardPreviewNotClickable)
      ) : (
        <Link href={getClickPathForItem()} passHref target={shouldOpenInNewTab ? "_blank" : ""}>
          <a target={shouldOpenInNewTab ? "_blank" : ""}>{cardContent(false)}</a>
        </Link>
      )}

      {(!hideButtonRow || !isMusicHomepage) && (
        <>
          {!collectionSlug ? (
            <div className={classNames(styles.priceContainer, styles.justifySpaceBetween)}>
              {!hasUserPurchasedProduct(productData.id) ? (
                <>
                  {!productData.dateDescription && (
                    <Link href={getClickPathForItem()} passHref>
                      <a>
                        <h2
                          className={classNames(
                            styles.salePrice,
                            offerTheme === "holiday" && styles.holidayTheme,
                            offerTheme === "spring" && styles.springTheme,
                          )}
                        >
                          {formatMoney(
                            !showPriceOffer
                              ? productData.priceInfo?.crossedPrice
                              : productData.priceInfo?.price,
                          )}{" "}
                          {showPriceOffer && (
                            <small
                              className={classNames(
                                styles.regularPrice,
                                offerTheme === "holiday" && styles.holidayTheme,
                                offerTheme === "spring" && styles.springTheme,
                              )}
                            >
                              <del>{formatMoney(productData.priceInfo?.crossedPrice)}</del>
                            </small>
                          )}
                        </h2>
                      </a>
                    </Link>
                  )}
                  {!productData.isSchool && showAddToCart && !(isMobileScreen && isHomePage) && (
                    <Button
                      variant="secondary"
                      buttonSize="small"
                      onClick={() => {
                        onAddToCart && onAddToCart();
                      }}
                      className={styles.ctaButtonSmall}
                    >
                      {alreadyInCart ? "Go to cart" : "Add to cart"}
                    </Button>
                  )}
                  {productData.isSchool && (
                    <Link href={getPathForSchool(productData)} passHref>
                      <Button
                        variant="secondary"
                        buttonSize="small"
                        onClick={() => { }}
                        className={styles.ctaButtonExpandedHorizontally}
                      >
                        {"Learn More"}
                      </Button>
                    </Link>
                  )}
                </>
              ) : (
                <>
                  <Link passHref href={getProductWatchPath(productData)}>
                    <a>
                      <h2 className={styles.salePrice}>In Library</h2>
                    </a>
                  </Link>
                  {!(isMobileScreen && isHomePage) && (
                    <Button
                      variant="secondary"
                      buttonSize="small"
                      className={styles.ctaButtonSmall}
                    >
                      Watch now
                    </Button>
                  )}
                </>
              )}
            </div>
          ) : productData.isCardPreviewNotClickable ? (
            <div className={styles.priceContainer}>
              <h2 className={styles.salePrice}>
                {productData.isCardPreviewNotClickable ? "Collection Exclusive" : "Included"}
                <small className={styles.regularPrice}>
                  <del>{formatMoney(productData.priceInfo.crossedPrice)}</del>
                </small>
              </h2>
            </div>
          ) : (
            <Link href={getClickPathForItem()} passHref>
              <a>
                <div className={styles.priceContainer}>
                  <h2 className={styles.salePrice}>
                    {productData.isCardPreviewNotClickable ? "Collection Exclusive" : "Included"}
                    <small className={styles.regularPrice}>
                      <del>{formatMoney(productData.priceInfo.crossedPrice)}</del>
                    </small>
                  </h2>
                </div>
              </a>
            </Link>
          )}
        </>
      )}
    </div>
  );
};
