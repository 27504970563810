import React, { CSSProperties, ReactElement } from "react";

import classNames from "classnames";

import { DotSeparatorIcon } from "~/components/common/Icons/DotSeparatorIcon";

import * as styles from "./CollectionInfo.css";

type CollectionInfo = {
  style?: CSSProperties;
  totalHours: number;
  totalLessons: number;
  totalClasses: number;
};

export const CollectionInfo = ({
  style,
  totalHours = 0,
  totalLessons = 0,
  totalClasses = 0,
}: CollectionInfo): ReactElement => {
  console.log("THE TOTAL HOURS here ", totalHours);
  return (
    <div className={classNames(styles.creatorInfoWrapWithoutHover)} style={style}>
      <span className={classNames(styles.creatorLink)}>
        {totalClasses.toLocaleString("en-US")} classes
      </span>
      <DotSeparatorIcon />
      <span className={classNames(styles.creatorLink)}>
        {totalLessons.toLocaleString("en-US")} lessons
      </span>
      <DotSeparatorIcon />
      <span className={classNames(styles.creatorLink)}>{Math.round(totalHours)}+ hours</span>
    </div>
  );
};
