import React from "react";
import { useTimeout } from "usehooks-ts";
import { useUserProductPurchaseForProduct } from "~/api/productApi";
import * as styles from "./VideoProgressBar.css";

export const VideoProgressBar = ({
  progressPercentage,
  barHeight,
  progressColor = styles.defaultProgressColor,
  backgroundColor = styles.defaultBackgroundColor,
  borderRadius = styles.defaultBorderRadius
}: {
  progressPercentage: number;
  barHeight: number;
  progressColor?: string;
  backgroundColor?: string;
  borderRadius?: number;
}): React.ReactElement => {

  const showProgressBar = progressPercentage > 0.0001;
  const getBoundedProgress = (progress: number) => {
    if (progress > 0 && progress < 0.03){
      return 0.03
    } else {
      return Math.min(Math.max(progressPercentage, 0), 1)
    }
  }
  const boundedProgress = getBoundedProgress(progressPercentage);
  const formattedPercentage = boundedProgress*100 + '%'

  return (
      <div
          className={showProgressBar ? styles.videoProgressWrap : styles.hideProgressBar}
          style={{borderRadius: `0px 0px ${borderRadius}px ${borderRadius}px`, height: barHeight}}>
          <div
              className={styles.videoProgressUnfilled}
              style={{ width: (100 - boundedProgress*100) + '%', height: barHeight, backgroundColor: backgroundColor }}
          >
          </div>
          <div
              className={styles.videoProgress}
              style={{ width: formattedPercentage, height: barHeight, backgroundColor: progressColor, bottom: 0}}
          ></div>
      </div>
  );
};
